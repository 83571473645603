@import 'variables';

.contact-us-wrapper{
    background-color: $onsight-blue-dark;
}

#map{
    border: 1px solid $onsight-blue;
}

.contactForm{
    background-color: $onsight-blue-dark;
    height: 20%!important;
    width: 40vh;
    border-radius: 10px;
}

.control{
    width: 100%;
    height: 4vh;
    margin-bottom: 2%;
    font-size: 0.8vw;
    font-family: $font-family;
    border-radius: 5px;
    border: 2px solid #374151;
    padding: 0;
    color: rgba(255,255,255, 0.7);
    background-color: #1F2937;
}

input:focus, textarea:focus, select:focus{
    outline: none;
}

.control-message{
    @extend .control;
    height: 8vh;
}

.control::-webkit-input-placeholder {
    opacity: 0.6;
}

@media screen and (max-width: 567px) {
    .form-control{
        height: 7vw;
        font-size: 5vw;
    }
}

@media screen and (max-width: 567px) {
    input[type="text"] {
        font-size: 3vw;
    }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    input[type="text"] {
        font-size: 2.5vw;
    }
}


@media screen and (min-width: 992px) and (max-width: 1112px) {
    input[type="text"] {
        font-size: 1.2vw;
    }
}

@media screen and (min-width: 1200px) {
    input[type="text"] {
        font-size: 1vw;
    }
}

@media screen and (min-width: 2200px) {
    input[type="text"] {
        font-size: 1vw;
    }
}



@media screen and (max-width: 567px) {
    input[type="email"] {
        font-size: 3vw;
    }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    input[type="email"] {
        font-size: 2.5vw;
    }
}


@media screen and (min-width: 992px) and (max-width: 1112px) {
    input[type="email"] {
        font-size: 1.5vw;
    }
}

@media screen and (min-width: 1200px) {
    input[type="email"] {
        font-size: 1vw;
    }
}

@media screen and (min-width: 2200px) {
    input[type="email"] {
        font-size: 1vw;
    }
}
