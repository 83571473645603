@import 'variables';

.btn {
    border-radius: 11px;
    display: inline-block;

    &-opaque {
        opacity: 0.7;
    }
}

.btn-portfolio-card{
  color: #1E2632;
  background-color: #8E9EB8;
  width: 7.5vw;
  font-size: 1.1rem;
  font-family: 'HelveticaNeue';
}

.btn-demo{
  @extend .btn-portfolio-card;
  color: white;
  background-color: $onsight-blue;
}

/* Bootstap-style button. Generated via http://blog.koalite.com/bbg/ */
.btn-large-white {
    color: #0a1c3d;
    background-color: #FFFFFF;
    width: 20%;
    font-size: 1vw;
    font-family: 'HelveticaNeue-Medium';
  }

  .btn-large-white:hover,
  .btn-large-white:focus,
  .btn-large-white:active,
  .btn-large-white.active,
  .open .dropdown-toggle.btn-large-white {
    color: #000000;
    background-color: #F2F4FA;
    border-color: #000000;
  }

  .btn-large-white:active,
  .btn-large-white.active,
  .open .dropdown-toggle.btn-large-white {
    background-image: none;
  }

  .btn-large-white.disabled,
  .btn-large-white[disabled],
  fieldset[disabled] .btn-large-white,
  .btn-large-white.disabled:hover,
  .btn-large-white[disabled]:hover,
  fieldset[disabled] .btn-large-white:hover,
  .btn-large-white.disabled:focus,
  .btn-large-white[disabled]:focus,
  fieldset[disabled] .btn-large-white:focus,
  .btn-large-white.disabled:active,
  .btn-large-white[disabled]:active,
  fieldset[disabled] .btn-large-white:active,
  .btn-large-white.disabled.active,
  .btn-large-white[disabled].active,
  fieldset[disabled] .btn-large-white.active {
    background-color: #FFFFFF;
    border-color: #000000;
  }

  .btn-large-white .badge {
    color: #FFFFFF;
    background-color: #000000;
  }

  .btn-download {
    color: #ffffff;
    background-color: #1E3051;
    width: 20%;
    font-size: 1vw;
    font-family: 'HelveticaNeue-Medium';
    vertical-align: middle;
  }

  .btn-download:hover,
  .btn-download:focus,
  .btn-download:active,
  .btn-download.active,
  .open .dropdown-toggle.btn-download {
    color: #ffffff;
    background-color: #1F4180;
    border-color: #1E3051;
  }

  .btn-download:active,
  .btn-download.active,
  .open .dropdown-toggle.btn-download {
    background-image: none;
  }

  .btn-download.disabled,
  .btn-download[disabled],
  fieldset[disabled] .btn-download,
  .btn-download.disabled:hover,
  .btn-download[disabled]:hover,
  fieldset[disabled] .btn-download:hover,
  .btn-download.disabled:focus,
  .btn-download[disabled]:focus,
  fieldset[disabled] .btn-download:focus,
  .btn-download.disabled:active,
  .btn-download[disabled]:active,
  fieldset[disabled] .btn-download:active,
  .btn-download.disabled.active,
  .btn-download[disabled].active,
  fieldset[disabled] .btn-download.active {
    background-color: #1E3051;
    border-color: #1E3051;
  }

  .btn-download .badge {
    color: #1E3051;
    background-color: #ffffff;
  }

  .btn-contact {
    color: #FFFFFF;
    background-color: #283046;
    font-size: 0.9vw;
    font-family: 'HelveticaNeue-Medium';
    vertical-align: middle;
    text-transform: none !important;
  }

  
  .btn-contact:hover,
  .btn-contact:focus,
  .btn-contact:active,
  .btn-contact.active,
  .open .dropdown-toggle.btn-contact {
    color: #1E3051;
    background-color: #6386C7;
    border-color: #808080;
  }

  .btn-contact:active,
  .btn-contact.active,
  .open .dropdown-toggle.btn-contact {
    background-image: none;
  }

  .btn-contact.disabled,
  .btn-contact[disabled],
  fieldset[disabled] .btn-contact,
  .btn-contact.disabled:hover,
  .btn-contact[disabled]:hover,
  fieldset[disabled] .btn-contact:hover,
  .btn-contact.disabled:focus,
  .btn-contact[disabled]:focus,
  fieldset[disabled] .btn-contact:focus,
  .btn-contact.disabled:active,
  .btn-contact[disabled]:active,
  fieldset[disabled] .btn-contact:active,
  .btn-contact.disabled.active,
  .btn-contact[disabled].active,
  fieldset[disabled] .btn-contact.active {
    background-color: #FFFFFF;
    border-color: #808080;
  }

  .btn-contact .badge {
    color: #FFFFFF;
    background-color: #1E3051;
  }


@media screen and (max-width: 567px) {
  .btn-large-white {
    font-size: 3.5vw;
    width: 45%;
  }

  .btn-download {
    font-size: 3vw;
    width: 45%;
  }

  .btn-contact {
    font-size: 3vw;
    width: 45%;
  }
  .btn-portfolio-card{
    width: 30vw;
    font-size: 3.5vw;
  }
}

@media screen and (max-width:800px )  and (orientation:landscape) {
  .btn-large-white {
    font-size: 2.5vw;
    width: 35%;
  }

  .btn-download {
    font-size: 2vw;
    width: 35%;
  }

  .btn-contact {
    font-size: 2vw;
    width: 35%;
  }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .btn-large-white {
    font-size: 2.5vw;
    width: 35%;
  }

  .btn-download {
    font-size: 2vw;
    width: 35%;
  }

  .btn-contact {
    font-size: 2vw;
    width: 35%;
  }
}


// Ipad Portrait
@media only screen 
    and (min-device-width: 768px) 
    and (max-device-width: 1024px) 
    and (orientation: portrait) 
    and (-webkit-min-device-pixel-ratio: 2) {
      .btn-portfolio-card{
        width: 15vw;
        font-size: 2vw;
      }
}

// Ipad Pro Landscape
@media only screen 
    and (min-device-width: 1024px) 
    and (max-device-width: 1366px) 
    and (-webkit-min-device-pixel-ratio: 2) 
    and (orientation: landscape){
      .btn-portfolio-card{
        width: 15vw;
        font-size: 1vw;
      }
}