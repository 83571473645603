@import 'variables';

.text-black {
    color: $black;

    &:hover {
        color: $black;
    }
}

.text-white {
    color: $white;
}

.text-light-blue{
    color: $light-blue;
}

.portfolio-card-description-color{
    color: $portfolio-card-description;
}

.text-shadow {
    text-shadow: 1px 1px 7px rgba(0, 0, 0, 0.5);
}

.text-what-we-do-card{
    font-family: $title-font;
    font-size: 1.2vw;
    color: $grey;
    cursor: default;
    h1 {
        font-size: 1.2vw;
    }
}

.text-what-we-do-description{
    font-size: 1.2vw;
    color: $grey;
}

.text-what-we-do-card::before {
    position: absolute;
    top: 0;
    left: -0.2em;
    width: 0;
    height: 0;
    content: '';
}

.text-what-we-do-card:hover{
    color: $onsight-blue;
    text-decoration: underline;
}

.what-we-do-number{
    font-family: $title-font;
    font-size: 1.1rem;
    margin-right: 5px;
    cursor: default;
    opacity: 0.2;
}

.text-team-member-card-name{
    font-family: $font-family;
    font-size: 1vw;
    color: #1e3051;
}

.text-team-member-card-position{
    @extend .text-team-member-card-name;
    font-family: $description-font;
    font-size: 0.8vw;
}

.text-team{
    color: #1e3051;
}

.text-title{
    font-family: $title-font;
    font-size: 1.8vw;

    h1 {
        font-size: 1.8vw;
    }
}

.title-portfolio-modal{
    @extend .text-title;
    font-size: 2vw !important;
    color: #1e3051;
}

.subtitle-portfolio-modal{
    color: #1e3051;
    font-size: 1.3vw !important;
    font-family: $title-font;
}

.text-description-modal{
    font-size: 0.9vw !important;
    color: #262626;
}

.text-sm {
    font-size: 0.75rem;
    th, tr, td {
        font-size: 0.75rem;
    }

    h1 {
        font-size: 1.25rem;
    }
}

.text-md {
    font-size: 0.65vw;
    th, tr, td {
        font-size: 1vw;
    }

    h1 {
        font-size: 1.5vw;
    }
}

.text-lg {
    font-size: 1.5rem;
    th, tr, td {
        font-size: 1.5rem;
    }

    h1 {
        font-size: 2rem;
    }
}

.portfolio-card-title{
    @extend .text-lg;
}

.portfolio-card-description{
    @extend .text-md;
}

.text-xl {
    font-family: $description-font;
    font-size: 1.2vw;
    th, tr, td {
        font-size: 2rem;
    }

    h1 {
        font-size: 1.2vw;
    }
}

.text-xxl {
    font-family: $title-font;
    font-size: 4vw;
    th, tr, td {
        font-size: 2rem;
    }

    h1 {
        font-size: 4vw;
    }
}

@media screen and (max-width: 567px) and (orientation:portrait) {
    .portfolio-card-title{
        font-size: 1.2rem;
    }
    .portfolio-card-description{
        font-size: 1.3vh!important;
    }
    .text-what-we-do-card {
        font-size: 3vw;
    }

    .text-title {
        font-size: 7vw;
    }

    .text-md {
        font-size: 4vw;
    }

    .text-xl {
        font-size: 6vw;
    }

    .text-xxl {
        font-size: 11vw;
    }

    .title-portfolio-modal{
        font-size: 7vw !important;
    }

    .text-description-modal{
        font-size: 4.5vw !important;
    }

    .text-team-member-card-name{
        font-size: 6vw !important;
    }

    .text-team-member-card-position{
        font-size: 4vw !important;
    }

    .subtitle-portfolio-modal{
        font-size: 6vw !important;
    }
    .text-what-we-do-description{
        font-size: 3vw!important;
    }

}

@media screen and (min-width: 768px) and (max-width: 992px) and (orientation:portrait) {
    .text-what-we-do-card {
        font-size: 2.7vw;
    }
    .portfolio-card-description{
        font-size: 1vw!important;
    }
    .portfolio-card-title{
        font-size: 2vw!important;
    }
    .what-we-do-number{
        font-size: 2.7vw;
    }
    .text-title {
        font-size: 3vw;
    }
    .text-md {
        font-size: 2.7vw;
    }
    .text-lg {
        font-size: 4vw;
    }
    .text-xl {
        font-size: 4vw;
    }
    .text-xxl {
        font-size: 9vw;
    }
    .text-team-member-card-name{
        font-size: 3vw !important;
    }
    .text-team-member-card-position{
        font-size: 2vw !important;
    }
    .subtitle-portfolio-modal{
        font-size: 2vw !important;
    }
    .text-what-we-do-description{
        font-size: 2.5vw!important;
    }

}

@media screen and (max-width:800px )  and (orientation:landscape) {
    .text-what-we-do-card {
        font-size: 2.7vw;
    }
    .what-we-do-number{
        font-size: 2.7vw;
    }
    .text-title {
        font-size: 3vw;
    }
    .text-md {
        font-size: 2.7vw;
    }
    .text-lg {
        font-size: 4vw;
    }
    .text-xl {
        font-size: 2vw;
    }
    .text-xxl {
        font-size: 5vw;
    }
    .text-team-member-card-name{
        font-size: 3vw !important;
    }
    .text-team-member-card-position{
        font-size: 2vw !important;
    }
    .subtitle-portfolio-modal{
        font-size: 2vw !important;
    }

}

@media screen and (min-width: 992px) and (max-width: 1112px) {
    .text-md {
        font-size: 0.6vw;
    }
    .text-what-we-do-card {
        font-size: 2vw;
    }
    .what-we-do-number{
        font-size: 2vw;
    }
    .text-title {
        font-size: 3vw;
    }
    .title-portfolio-modal{
        font-size: 4vw !important;
    }
    .text-description-modal{
        font-size: 2.5vw !important;
    }
    .text-team-member-card-name{
        font-size: 2vw !important;
    }
    .text-team-member-card-position{
        font-size: 1.7vw !important;
    }
    .subtitle-portfolio-modal{
        font-size: 3.7vw !important;
    }
    .text-what-we-do-description{
        font-size: 1.9vw!important;
    }

}