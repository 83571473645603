@import 'variables';

.side-navigation {
    position: fixed;
    top: 50%;
    z-index: 55;
    padding-bottom: 2px;
    transform: translateY(-50%);
    opacity: 1;
    transition: opacity 0.3s;
}

.side-navigation-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 60px;
    margin-left: 10px;
    padding: 18px 22px 12px 14px;
    background-color: rgba(0, 0, 0, 0.15);
    border: solid 1px rbga(255, 255, 255, 0.85);
    list-style: none;
    border-radius: 4px;
}


.side-navigation-item {
    position: relative;
    display: block;
    color: rgba(255, 255, 255, 0.85);
    font-weight: 800;
    font-size: 14px;
    font-family: "ProximaNova", sans-serif;
    letter-spacing: 0.7px;
    text-decoration: none;
    transition: color 0.2s, background-color 0.3s;
}

.side-navigation-item-last{
    @extend .side-navigation-item
}

.number {
    position: relative;
    padding: 5px 30px 5px 0px;
}

.hovered-white:hover {
    cursor: pointer;
    color: #91a3b0;
}

.hovered-blue:hover {
    cursor: pointer;
    color: $onsight-blue;
}


.title-onhover {
    position: absolute;
    top: 0;
    left: 100%;
    padding: 0 0.4em;
    letter-spacing: 0;
    white-space: nowrap;
    text-transform: uppercase;
    display: none;
    transition: opacity 0.3s, background-color 0.3s;
}

.number:hover + .title-onhover {
    display: inline-block;
}

.side-navigation-item::after{
    display: block;
    box-sizing: content-box;
    width: 0;
    height: 0;
    border-top: 2px solid transparent;
    border-left: 2px solid transparent;
    transition: height 0.3s 0.1s, width 0.1s, border-color 0s 0.4s;
    transition-timing-function: linear;
    content: '';
}

.active::after{
    width: 20px;
    height: 100px;
    border-color: rgba(255, 255, 255, 0.85);
    transition: height 0.3s 0.1s, width 0.1s 0.4s, border-color 0s 0.1s;
    transition-timing-function: linear;
}
