@import 'variables';

.card-what-we-do{
  width: 50vw;
  height: 40vh;
  background-color: rgba(255, 255, 255, 0.97);
  box-shadow: 10px 10px 15px rgba(255, 255, 255, 0.3);
  border-radius: 1px;
  position: relative;
}

.what-we-do-active{
  font-size: 2.75rem;
  color: $onsight-blue;
  font-weight: 900;
  position: relative;
}

.what-we-do-active::before {
  width: 15px;
  height: 100%;
  border-top: 2px solid $grey;
  border-left: 2px solid $grey;
  content: '';

}

.what-we-do-wrapper{
    z-index: 1;
    min-height: 200px;
    text-align: center;
    display: flex;
    justify-content: center;
}

.what-we-do-list{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    list-style-type: none;
    align-items: flex-start;
    padding-left: 10%;
    min-height: 30vh;
    border-right: 4px solid $onsight-blue;
}

.what-we-do-list-detailed{
  @extend .what-we-do-list;
  border-right: none !important;
  align-items: center!important;
  padding: 0;
}


@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .what-we-do-card {
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    background-color: rgba(255, 255, 255, 0.6);
  }
  .warning {
    display: none;
  }
}

.what-we-do-badge{
    min-height: 43px;
    min-width: 105px;
    width: fit-content;
    height: fit-content;
    padding: 6px 17px 10px 18px;
    border-radius: 20px;
    border: solid 1px #707070;
    background-color: #1e3051;
    color: white;
    font-family: $description-font;
    font-size: 1vw;

}


@media screen and (max-width: 567px) {
  .what-we-do-wrapper {
    min-height: 120px;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .what-we-do-badge {
    min-height: 20px;
    min-width: 70px;
    font-size: 5vw;
  }
  .card-what-we-do{
    width: 97vw;
  }
  .what-we-do-active{
    font-size: 0.9rem;
  }
  .what-we-do-list{
    padding-left: 5%;
  }
}

@media screen and (min-width: 768px) and (max-width: 992px) and (orientation:portrait) {
  .card-what-we-do{
    width: 90vw;
  }
  .what-we-do-active{
    font-size: 1.6rem;
  }
}


@media screen and (min-width: 768px) and (max-width: 1112px) {
  .what-we-do-badge {
    min-height: 20px;
    min-width: 120px;
    font-size: 1.5vw;
  }
  .card-what-we-do{
    width: 80vw;
  }
}

@media screen and (max-width:800px )  and (orientation:landscape) {
  .what-we-do-badge {
    font-size: 3vw;
  }
}


// Ipad Pro Landscape
@media only screen 
    and (min-device-width: 1024px) 
    and (max-device-width: 1366px) 
    and (-webkit-min-device-pixel-ratio: 2) 
    and (orientation: landscape){
      .what-we-do-active{
        font-size: 2rem;
      }
}



// Laptops
@media screen and (min-width: 1024px) and (max-height: 1310px) {
  .what-we-do-active{
    font-size: 2rem;
  }
}

