@import 'variables';

.team-content-wrapper{
    min-height: 200px;
    display: flex;
    justify-content: center;
}

.team-card {
    max-width: 250px!important;
    transition: 0.3s;
    border-radius: 10px;
    padding: 5%;
    padding-bottom: 0;
}

.team-card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.15);
}


.team-img{
    height: 200px;
    width: 200px;
}

.card-content{
    padding-left: 6.5%;
}

.emoji-background{
    height: 170px;
    width: 190px;
    position: absolute;
    z-index: -100;
    border-radius: 10px;
}

.emoji-background-hovered{
    @extend .emoji-background;
    box-shadow: 0 8px 10px 0 rgba(0,0,0,0.7)
}

.page-aligment{
    margin-top: 4%;
    margin-bottom: 4%
}

.modal-body-form{
    border-radius: 12px;
}

.teamForm{
    background-color: white;
    height: 20%!important;
    width: 90vh;
    border-radius: 10px;
}

.control-team{
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    margin-bottom: 2%;
    padding-left: 1%;
    font-size: 0.8vw;
    font-family: $font-family;
    border-radius: 5px;
    border: 2px solid #374151;
    color: $onsight-blue-dark;
}

.control-team-message{
    @extend .control-team;
    height: calc(5em + 2rem + 2px);
}

.control-drag-n-drop{
    height: calc(6em + 2.5rem);
}

.custom-file-label{
    border-radius: 5px;
    border: 2px solid #374151;
    color: $onsight-blue-dark;
}

.custom-file-label::after{
    height: 100%!important;
    color: #FFFFFF;
    background-color: #283046;
    font-family: 'HelveticaNeue-Medium';
    vertical-align: middle;
    border-radius: 0 0.2rem 0.2rem 0;
}

@media screen and (max-width: 567px) {
    .team-card {
        padding: 1%!important;
    }
    .card-content{
        padding-left: 10.5%!important;
        padding-right: 10.5%!important;
    }
}

// Ipad Portrait
@media only screen 
    and (min-device-width: 768px) 
    and (max-device-width: 1024px) 
    and (orientation: portrait) 
    and (-webkit-min-device-pixel-ratio: 2) {
        .team-img{
            height: 160px;
            width: 160px;
        }
        .emoji-background{
            height: 150px;
            width: 150px;
        }
        .card-content{
            padding-left: 5%!important;
            padding-right: 5%!important;
        }
}

//  Ipad horizontal
@media only screen 
    and (min-device-width: 768px) 
    and (max-device-width: 1024px) 
    and (orientation: landscape) 
    and (-webkit-min-device-pixel-ratio: 2) {
        .card-content{
            padding-left: 3%!important;
            padding-right: 3%!important;
        }
}

//team background colors
#pink{
    background-color: #F8C2DD;
}
#golden{
    background-color: #FAC105;
}
#light-blue{
    background-color: #A2E4F5;
}
#blend-blue{
    background-color: #3A405A;
}
#green{
    background-color: #659B5E;
}

#bg-grey{
    background-color: rgb(245,245,245, 0.9);
}