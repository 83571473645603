@import 'variables';

.card{
    width: 17vw;
    height: 45vh!important;
    background-color: $portfolio-card-bg;
    border-radius: 40px;
    box-shadow: 15px 15px 40px 40px rgba(27,33,43, 0.5);
}

.card-mobile{
    width: 80vw;
    height: 50vh!important;
    background-color: $portfolio-card-bg;
    border-radius: 40px;
}

.portfolio-button-wrapper{
    height: 260px;
}

.portfolio-page{
    background-color: $portfolio-bg;
}

.card-wrapper{
    height: 40vh;
    width: 15vw;
}

.carousel-wrapper{
    width: 75%;
}

.carousel-inner{
    height: 55vh!important;
}

// onhover card animation
.card-wrapper-animation{
	animation: scale-in-center 0.5s;
    transition-timing-function: ease-in-out;
}

@-webkit-keyframes scale-in-center {
    0% {
        -webkit-transform: scale(0);
            transform: scale(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1.1);
            transform: scale(1.1);
        opacity: 1;
    }
}
    @keyframes scale-in-center {
    0% {
        -webkit-transform: scale(0);
            transform: scale(0);
            opacity: 1;
    }
    100% {
        -webkit-transform: scale(1.1);
            transform: scale(1.1);
        opacity: 1;
    }
}  

.modal-body{
    background-color: $portfolio-card-bg;
    border-radius: 12px;
}

.link{
    color: $light-blue!important;
}

.subtitle-portfolio-modal{
    color: $white;
}

.text-description-modal{
    color: $portfolio-card-description;
}

// carousel controls
.controlsBlock {
    position: relative;
    bottom: 0;
    left: 0;
    top: 10px;
    display: block;
    width: 20%;
    margin: 0 auto;
    z-index: 9;
    height: 4vh;
    text-align: center;
}

.small-logo{
    width: 35%;
    height: 35%;
}

.small-logo-modal{
    width: 100%;
    height: 100%;
}


.small-logo-hovered{
    height: 180px;
    width: 170px;
}

.title-portfolio-modal{
    color: $white;
}

.animated-line-enter {
	-webkit-animation: slide-in-left 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation: slide-in-left 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-in-left {
    0% {
        -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
            transform: translateX(0);
        opacity: 1;
    }
}
@keyframes slide-in-left {
    0% {
        -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
            transform: translateX(0);
        opacity: 1;
    }
}  

.animated-content-after-hover{
    -webkit-animation: slide-in 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-in {
    0% {
        -webkit-transform: translateY(295px);
            transform: translateY(295px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
            transform: translateY(0);
        opacity: 1;
    }
}
@keyframes slide-in {
    0% {
        -webkit-transform: translateY(295px);
            transform: translateY(295px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
            transform: translateY(0);
        opacity: 1;
    }
}

.header-wrapper{
    height: 3vh;
}

.modal-image{
    width: 70%;
    height: 70%;
}

.card-modal{
    width: 100%;
}

.rounded-badge-modal{
    @extend .rounded-badge;
}

.rounded-badge{
    min-width: 65px;
    min-height: 20px;
    margin: 10px 10px 10px 10px;
    padding: 5px 5px 5px 5px;
    border-radius: 21px;
    background: #ffffff;
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.15);
    width: fit-content;
    height: fit-content;
    font-family: $title-font;
}


#avdocs{
    color: #ffffff ;
}

#avdocs.rounded-badge{
    color: #080430;
}

#wellyou{
    color: #333333 ;
}

#wellyou.rounded-badge{
    color: #6fadea;
}

#virus{
    color: #707070;
}

#virus.rounded-badge{
    color: #707070;
}

#geist{
    color: #ffffff;
}

#geist.rounded-badge{
    color: #fd8c6b;
}

#jumpon{
    color: #ffffff;
}

#jumpon.rounded-badge{
    color: #fd0f44;
}

@media screen and (max-width: 567px) {
    .rounded-badge {
        min-height: 15px;
        min-width: 50px;
    }

    .rounded-badge-modal{
        max-height: 25px;
        max-width: 85px;
        font-size: 2.4vw !important;
        padding: 1px 1px 1px 1px;
    }

    .header-wrapper {
        height: 10vh;
    }
    .card{
        width: 25vw;        
    }
    .card-wrapper{
        width: 80vw;
    }
    .controlsBlock{
        width: 35%;
    }
    .carousel-wrapper{
        width: 100%;
    }
    .carousel-item{
        padding: 0 0 0 0;
    }
    .portfolio-button-wrapper{
        height:100px;
    }
    .portfolio-wrapper{
        height: 50vh;
    }
    .small-logo-hovered{
        height: 55%;
        width: 55%;
    }

    .small-logo-modal{
        width: 85px;
        height: 105px;
    }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .rounded-badge {
        min-height: 15px;
        min-width: 50px;
    }

    .rounded-badge-modal{
        min-height: 15px;
        min-width: 50px;
    }
    
}


//  Ipad landscape
@media only screen 
    and (min-device-width: 768px) 
    and (max-device-width: 1024px) 
    and (orientation: landscape) 
    and (-webkit-min-device-pixel-ratio: 2) {
        .header-wrapper {
            height: 10vh;
        }
        .card{
            width: 25vw;
            height: 60vh;
        }
        .card-wrapper{
            width: 25vw;
            height: 60vh;
        }
        .small-logo-modal{
            width: 110px;
            height: 129px;
        }
}

// Ipad Porttrait
@media only screen 
    and (min-device-width: 768px) 
    and (max-device-width: 1024px) 
    and (orientation: portrait) 
    and (-webkit-min-device-pixel-ratio: 2) {
        .header-wrapper {
            height: 10vh;
        }
        .card{
            width: 20vw;
            height: 40vh!important;
        }
        .card-wrapper{
            width: 40vw!important;
            height: 40vh!important;
        }
        .carousel-wrapper{
            width: 100%!important;
        }        
        .small-logo-hovered{
            height: 90px;
            width: 80px;
        }
        .small-logo-modal{
            width: 100px;
            height: 120px;
        }
}

// Ipad pro landscape
@media only screen 
    and (min-device-width: 1024px) 
    and (max-device-width: 1366px) 
    and (-webkit-min-device-pixel-ratio: 2) 
    and (orientation: landscape){
        .header-wrapper {
            height: 10vh;
        }
        .card{
            width: 20vw;
            height: 60vh;
        }
        .card-wrapper{
            width: 20vw;
            height: 60vh;
        }
        .small-logo-hovered{
            height: 145px;
            width: 135px;
        }
}

// Ipad Pro Portrait
@media only screen 
    and (min-device-width: 1024px) 
    and (max-device-width: 1366px) 
    and (-webkit-min-device-pixel-ratio: 2) 
    and (orientation: portrait){
        .header-wrapper {
            height: 10vh;
        }
        .card{
            width: 20vw;
            height: 60vh;
        }
        .card-wrapper{
            width: 20vw;
            height: 60vh;
        }
        .small-logo-hovered{
            height: 145px;
            width: 135px;
        }

}

// Laptops
@media screen and (min-width: 1024px) and (max-height: 1310px) {
    .header-wrapper {
        height: 15vh;
    }
    .small-logo-hovered{
        height: 130px;
        width: 120px;
    }
}

@media screen and (min-width: 1600px) and (max-height: 1600px) {
    .header-wrapper {
        height: 15vh;
    }
    .small-logo-hovered{
        height: 180px;
        width: 170px;
    }
}