@import 'text';
@import 'variables';
@import 'buttons';

// page specific
@import 'hero';
@import 'portfolio';
@import 'what-we-do';
@import 'team';
@import 'contact-us';
@import 'footer';
@import 'navigation';

html {
    height: 100%;
    width: 100vw;
    scroll-behavior: smooth !important;
    font-size: 80%;
    overflow-x: hidden!important;

}

body {
    min-height: 100%;
    font-family: $font-family;
    overflow-x: hidden!important;
}

#root{
    width: 100vw;
}

.badge {
    height: 15vh;
    width: 15vh;
    display: table-cell;
    vertical-align: middle;
    border-radius: 50%;
    border: 2px solid black;
    color: black;
}

.cirle-text{
    text-align: center;
}

.btn-container {
    height: 2.8vw;
    margin-top: 13%;
    margin-bottom: 2%;
    text-align: center;
}

@media screen and (max-width: 567px) {
    .btn-container {
        height: 5vw;
    }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .btn-container {
        height: 4vw;
        margin-top: 10%;
        margin-bottom: 1%;
    }
}

@media screen and (min-width: 992px) and (max-width: 1112px) {
    .btn-container {
        margin-top: 13%;
        margin-bottom: 2%;
    }
}

@media screen and (min-width: 2200px) {
    .btn-container {
        margin-top: 20%;
        margin-bottom: 10%;
    }
}

@media screen and (max-width:800px )  and (orientation:landscape) {
    .btn-container {
        margin: 0;
    }
}

section {
    scroll-snap-align: center;
}

@media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}
    .sidenav a {font-size: 18px;}
}

/* for sm resolution */
@media screen and (max-width: 567px) {
    html {
        font-size: 80% !important;
    }
}
