@import 'variables';

.footer {
    background-color: #091B33;
    // background-color: $onsight-blue-dark;
    padding-top: 2%;
    padding-bottom: 3%;
}

.back-to-top {
    color: white;
}