// colors
$white: #FFFFFF;
$black: #333333;
$grey: #999999;
$onsight-blue: #1E3051;
$onsight-blue-dark: #101827;
$portfolio-bg: #171C29;
$light-blue: #3BAFC9;
$portfolio-card-bg: #28303F;
$portfolio-card-description: #8792AC;

$spacer: 3rem;

// font size
$font-size-h1: 4rem;
$font-size-h2: 1.25rem;
$font-size-p: 1rem;
$title-font: 'HelveticaNeue-Medium';
$description-font: 'HelveticaNeue-Light';
$font-family: 'HelveticaNeue';

// SCREEN SIZES
$screen-xs: 320px;
$screen-sm: 480px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;